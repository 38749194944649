import React, {useState} from "react";
import {
    HomeIcon,
    ArrowsRightLeftIcon,
    Cog6ToothIcon,
    ArrowLeftOnRectangleIcon,
    Bars3Icon,
    XMarkIcon
} from "@heroicons/react/24/outline";

import oculusIcon from "../../assets/oculus_icon_inverse.svg"

const navigation = [
    {name: 'Verwaltung', href: '/homepage', icon: HomeIcon, isActive: true},
    {name: 'Import/Export', href: '/import-export', icon: ArrowsRightLeftIcon, isActive: false},
    {name: 'Einstellungen', href: '/homepage', icon: Cog6ToothIcon, isActive: false},
]
const logoutIcon = {name: 'Logout', href: '/login', icon: ArrowLeftOnRectangleIcon};
export default function Navigation() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(prevState => !prevState);
    };

    const menuBtn = {
        name: isMenuOpen ? 'Einklappen' : 'Ausklappen',
        icon: isMenuOpen ? XMarkIcon : Bars3Icon
    };
    return (
        <div className={`flex flex-col ${isMenuOpen ? "w-64" : ""}`}>
            <div className="flex min-h-0 flex-1 flex-col bg-blue3">
                <div className="flex-1">
                    <div className="flex items-center justify-center bg-blue3 py-4">
                        <img
                            className="h-8 w-auto"
                            src={oculusIcon}
                            alt="Oculus Icon"
                        />
                    </div>
                    <nav aria-label="Sidebar" className="flex flex-col items-center space-y-3 py-6">
                        <button onClick={toggleMenu}
                                className="flex focus-visible:outline-white focus-visible:!outline-1 focus-visible:outline-offset-1 gap-x-5 w-full items-center p-4 text-white hover:bg-oculus">
                            <menuBtn.icon className="h-6 w-6 " aria-hidden="true"/>
                            <span className={` ${isMenuOpen ? "" : "sr-only"}`}>{menuBtn.name}</span>
                        </button>
                        {navigation.map((item) => {
                            const IconComp = item.icon
                            return <a
                                key={item.name}
                                href={item.href}
                                className={`flex focus-visible:outline-white focus-visible:!outline-1 focus-visible:outline-offset-1 items-center p-4 text-white hover:bg-oculus gap-x-5 w-full ${item.isActive ? "bg-blue2" : ""}`}
                            >
                                <IconComp className="h-6 w-6" aria-hidden="true"/>
                                <span className={` ${isMenuOpen ? "" : "sr-only"}`}>{item.name}</span>
                            </a>
                        })}
                    </nav>
                </div>
                <div className="flex flex-shrink-0 pb-5">
                    <a
                        key={logoutIcon.name}
                        href={logoutIcon.href}
                        className="flex items-center focus-visible:outline-white focus-visible:!outline-1 focus-visible:outline-offset-1 p-4 text-white hover:bg-oculus gap-x-5 w-full"
                    >
                        <logoutIcon.icon className="h-6 w-6" aria-hidden="true" aria-label={"Profile Picture"}/>
                        <span className={` ${isMenuOpen ? "" : "sr-only"}`}>{logoutIcon.name}</span>
                    </a>
                </div>
            </div>
        </div>
    )
}