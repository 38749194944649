import './src/styles/global.css'

import React from "react";
import Layout from "./src/components/layout";
import {navigate} from "gatsby";

export const wrapPageElement = ({element, props}) => {
    const userToken = localStorage.getItem("userToken")
    if (!userToken) {
        if (process.env.IS_LOCAL === "false") {
            navigate('/login');
        }
    }
    const isIndexPage = props.location.pathname === "/login/";
    return isIndexPage ? <>{element}</> : <Layout>{element}</Layout>;
};


